import { createRouter, createWebHashHistory } from 'vue-router'
import layout from "@/views/layout/index"
const routes = [{
    path: '/',
    // 重定向 进入首页
    redirect: '/home',
    component: layout,
    children: [
        // 首页
        {
            path: '/home',
            name: 'home',
            component: () =>
                import ('@/views/Home/index')
        },
        // 硅胶手机壳
        {
            path: '/local',
            name: 'local',
            component: () =>
                import ('@/views/local/index')
        },
        // TPU手机壳
        {
            path: '/enlightenment',
            name: 'enlightenment',
            component: () =>
                import ('@/views/enlightenment/index')
        },
        // 主题
        {
            path: '/topic',
            name: 'topic',
            component: () =>
                import ('@/views/topic/index')
        },
        // 益智
        {
            path: '/puzzle',
            name: 'Puzzle',
            component: () =>
                import ('@/views/Puzzle/index')
        },
        // 其他
        {
            path: '/other',
            name: 'other',
            component: () =>
                import ('@/views/other/index')
        },
        // AboutUs
        {
            path: '/AboutUs',
            name: 'AboutUs',
            component: () =>
                import ('@/views/AboutUs/index')
        },
        // 登录
        {
            path: '/login',
            name: "login",
            component: () =>
                import ('@/views/Login/index')
        },
        // 注册
        {
            path: "/enroll",
            name: "enroll",
            component: () =>
                import ('@/views/enroll/index')
        },
        // 信息发布
        {
            path: '/publishInfo',
            name: "publishInfo",
            component: () =>
                import ('@/views/publishInfo/index')
        },
        // 我的发布
        {
            path: '/Mypublish',
            name: "Mypublish",
            component: () =>
                import ('@/views/Mypublish/index')
        },
        // 咨询

        {
            path: '/consultation',
            name: 'consultation',
            component: () =>
                import ('@/views/consultation/index')
        },
        {
            path: "/ToyDetails",
            name: "ToyDetails",
            component: () =>
                import ('@/views/ToyDetails/index')
        },
        {
            path: "/ToyDetailshome",
            name: "ToyDetailshome",
            component: () =>
                import ('@/views/ToyDetails/home')
        },
        // 注册许可
        {
            path: "/Useragr",
            name: "Useragr",
            component: () =>
                import ('@/views/Useragr/index')
        }
    ]
}]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router