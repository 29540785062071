<template>
  <div class="bottom">
    <!-- <div class="bottom-list">
      <div class="bottom-item" v-for="(item, index) in bomList" :key="index">
        <img class="bottom-item-img" :src="item.img" alt="" />
        <div class="bottom-item-title">{{ item.title }}</div>
      </div>
    </div> -->
    <div class="bottom-text">
      <div>公司地址：浙江省杭州市滨江区辅沿街道浦沿路88号1幢3楼35022室</div>
      <div>公司电话：4000879683</div>
      <div>公司邮箱：2853700312@qq.com</div>
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank"> {{ icp }}</a>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref } from "vue"
  const bomList = [
    { img: require("../img/bom1.png"), title: "正品采购" },
    { img: require("../img/bom2.png"), title: "专业仓储" },
    { img: require("../img/bom3.png"), title: "金牌服务" },
    { img: require("../img/bom4.png"), title: "荣誉口碑" },
    { img: require("../img/bom5.png"), title: "优良口碑" },
  ]
  const icp = ref("")
  icp.value = gIcpNo
</script>
<style lang="scss" scoped>
  .bottom {
    width: 100%;
    height: 120px;
    background: #edb725;
    font-size: 16px;
  }
  .bottom-list {
    width: 800px;
    padding: 15px 80px;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid #ffff;
    justify-content: space-around;
    margin: 0px auto;
  }
  .bottom-item-title {
    color: #fff;
  }
  .bottom-item-img {
    width: 61px;
    height: 61px;
  }
  .bottom-text {
    height: 100%;
    text-align: center;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>
