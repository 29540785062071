<template>
  <el-menu
    :default-active="activeMenu"
    class="el-menu-demo"
    mode="horizontal"
    text-color="#333333"
    active-text-color="#EDB725"
    router
  >
    <el-menu-item index="/home">首页</el-menu-item>
    <el-menu-item index="/local">硅胶手机壳</el-menu-item>
    <el-menu-item index="/enlightenment">TPU手机壳</el-menu-item>
    <el-menu-item index="/topic">皮革手机壳</el-menu-item>
    <el-menu-item index="/other">其他</el-menu-item>
    <el-menu-item index="/publishInfo">信息发布</el-menu-item>
    <el-menu-item index="/Mypublish">我的发布</el-menu-item>
    <el-menu-item v-if="!isLogin" index="/login">登录</el-menu-item>
    <el-menu-item v-if="!isLogin" index="/enroll">注册</el-menu-item>
    <div
      v-if="isLogin"
      class="layout-contern-login"
      @click="handoleSignOutClick"
    >
      退出登录
    </div>
  </el-menu>
  <!-- <div class="menu">
    <router-link
      v-for="(item, index) in routerList"
      :key="index"
      :class="{ 'menu-litm': true, 'active-menu-item': index == onactive }"
      :to="item.to"
      @click="routerClick(index)"
      >{{ item.title }}</router-link
    >
  </div> -->
</template>
<script setup>
  import { computed, ref, defineProps, onUnmounted } from "vue"
  import { useRoute, useRouter } from "vue-router"
  import Bus from "@/bus/index"
  const routerList = [
    {
      to: "/home",
      title: "首页",
    },
    {
      to: "/AboutUs",
      title: "AboutUs",
    },
    {
      to: "/publishInfo",
      title: "信息发布",
    },
    {
      to: "/Mypublish",
      title: "我的发布",
    },
  ]
  const props = defineProps({
    active: {
      type: Number,
    },
  })
  console.log(props.active)
  const onactive = ref(0)
  console.log(onactive.value)
  // 路由选中状态
  const routerClick = (index) => {
    onactive.value = index
    console.log(onactive.value)
  }

  const route = useRoute()
  const router = useRouter()
  const activeMenu = computed(() => {
    const { path } = route
    return path
  })

  const isLogin = ref(false)
  const handoleLgionClick = () => {
    router.push("/login")
  }
  console.log(isLogin.value)
  const active = ref(0)
  isLogin.value = sessionStorage.isLogin
  console.log(isLogin.value)
  const handoleEnrollClick = () => {
    router.push("/enroll")
  }
  const handoleSignOutClick = () => {
    router.push("/")
    sessionStorage.removeItem("isLogin")
    isLogin.value = sessionStorage.isLogin
  }
  Bus.$on("addAge", (num) => {
    isLogin.value = num
    console.log(num)
  })

  //组件卸载时，取消订阅
  onUnmounted(() => {
    Bus.$off("addAge")
  })
</script>
<style lang="scss" scoped>
  .layout-contern-login {
    font-size: 14px;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
  }
  .el-menu--horizontal > .el-menu-item {
    border-bottom: none;
    text-decoration: none;
  }
  :deep .el-menu-item {
    color: #000;
    height: 0px;
  }
  :deep .is-active {
    color: #fff;
    background-color: none;
    span {
      color: #fff !important;
    }
  }
  .denglu {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #ffffff;
  }
  .el-menu--horizontal {
    // padding: 0 300px;
    box-sizing: border-box;
    display: flex;
    // height: 0px;
    justify-content: flex-end;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border: none;
    height: 0px;
  }
  .menu {
    width: 100%;
    background-color: #71a28d;
    padding: 0 300px;
    display: flex;
  }
  .menu-ul {
    flex: 5;
    display: flex;
    justify-content: flex-end;
  }
  .menu-ul li {
    flex: 1;
    line-height: 70px;
    color: #fff;
    text-align: center;
  }
  .menu-ul li a {
    display: block;
    line-height: 70px;
    color: #fff;
    text-align: center;
  }
  .menu-litm {
    display: block;
    flex: 1;
    line-height: 70px;
    color: #fff;
    text-align: center;
  }
  .active-menu-item {
    background-color: #ff8420;
  }
  .layout-contern-btn {
    display: flex;
    margin-left: 20px;
    color: #fff;
    text-align: center;
    justify-content: space-evenly;
  }
  .el-submenu__title:hover {
    background-color: rgb(3, 19, 33) !important;
  }

  //子级的类名

  .el-menu-item:hover {
    background-color: rgba(#fff, $alpha: 0) !important;
  }
</style>
